import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';

const  TodoListMobileMenuStyle = styled('div')(({ theme }) => ({
    '& .MuiButtonBase-root': {
      color: '#fff',
      zIndex: 1,
      fontSize: 18,
      backgroundImage: 'linear-gradient( 136deg, #7c3cf2 0%, #6161f5 50%, #4180f8 100%)',
      '& .MuiSpeedDialAction-fab': {
        backgroundImage: 'none',
        backgroundColor: 'red'
      }
    },
}));


interface Props {
  createNewItem:Function,
  setEditModalOpen:Function,
  setDeleteModalOpen:Function
}

const TodoListMobileMenu = ({createNewItem,setEditModalOpen,setDeleteModalOpen}:Props): JSX.Element => {
    const actions = [
        { icon: <i className="fa-solid fa-plus"></i>, name: 'New', onClick:()=>createNewItem()},
        { icon: <i className="fa-solid fa-pen"></i>, name: 'Rename', onClick:()=>setEditModalOpen(true)},
        { icon: <i className="fa-solid fa-trash"></i>, name: 'Delete', onClick:()=>setDeleteModalOpen(true)},
    ];

  return (
    <TodoListMobileMenuStyle>
    <div className="md:hidden fixed bottom-5 right-5">
        <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<i className="fa-solid fa-ellipsis-vertical"></i>}
            style={{backgroundImage: 'linear-gradient( 136deg, #7c3cf2 0%, #6161f5 50%, #4180f8 100%) !important'}}
        >
            {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
                style={{backgroundColor:'blue'}}
            />
            ))}
        </SpeedDial>
        </Box>
    </div>
    </TodoListMobileMenuStyle>
  );
}
export default TodoListMobileMenu;