import 'css/App.css';
import React from 'react';
import ToDoListPage from 'pages/ToDoListPage';
import DashboardPage from 'pages/DashboardPage';
import AppContainer from 'pages/AppContainer';
import ListsStore from 'stores/ListsStore';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

export default function App() {
  ListsStore.getLists();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppContainer />}>
          <Route index element={<DashboardPage />} />
          <Route path="lists/:listId" element={<ToDoListPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
