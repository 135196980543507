import { observable, makeObservable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import ToastStore from 'stores/ToastStore';
import List from 'interfaces/List';
import ListSet from 'interfaces/ListSet';
import { useRef } from 'react';
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class Store extends BaseStore {
    lists:ListSet = {is_fetching:false} as ListSet;
    list:List = {is_fetching:false} as List;

    constructor() {
        super();
        makeObservable(this, {
            lists: observable,
            list: observable
        });
    }

    getLists = (force:boolean=false) => {
        if((this.lists.is_fetching === false && !this.lists.data) || force === true){
            if(force===false){
                this.lists.is_fetching = true;
            }
            const options = this.preFetch({ method: 'get'});

            fetch(`${apiBaseUrl}/api/lists`, options)
            .then(response => response.json())
            .then((data:ListSet):void => {this.lists = data;this.lists.is_fetching=false;});
        }
    }

    getList = (listId:number, force:boolean=false):void => {
        if((this.list.is_fetching === false && !this.list.id) || force === true){
            if(force===false){
                this.list.is_fetching = true;
            }
            const options = this.preFetch({ method: 'get'});

            fetch(`${apiBaseUrl}/api/lists/${listId}`, options)
            .then(response => response.json())
            .then((data:List) => {this.list = data;this.list.is_fetching=false;});
        }
    }

    createNew = (listName:string, icon:string) => {
        const options = this.preFetch({
            method: 'post',
            body: JSON.stringify({"listname": listName, "icon": icon})
        });
        
        fetch(`${apiBaseUrl}/api/lists}`, options)
        .then(response => response.json())
        .then(() => this.getLists(true));
    }

    delete = (list:List, callback:Function|null = null) => {
        let summary = list.name;

        const options = this.preFetch({ method: 'delete'});
        
        fetch(`${apiBaseUrl}/api/lists/${list.id}}`, options)
        .then(response => response.json())
        .then(() => {
            this.deleteListFromStoreById(list.id);
            ToastStore.notifySuccess(`List: "${summary}" was succesfully deleted.`);
            callback && callback();
        });
    }

    update = (list:List, name:string, icon:string) => {
        this.updateListInStoreById(list.id, name, icon)

        const options = this.preFetch({ 
            method: 'post',
            body: JSON.stringify({"name": name,"icon":icon})
        });
        
        fetch(`${apiBaseUrl}/api/lists/${list.id}}`, options)
        .then(response => response.json())
        .then(()=>{
            ToastStore.notifySuccess(`List: "${name}" was succesfully updated.`)
        });
    }

    updateListInStoreById = (id:number, name:string, icon:string) => {
        this.lists && this.lists.data.forEach((list:List, key:number) => {
            if(list.id === id){
                this.lists.data[key].name = name;
                this.lists.data[key].icon = icon;
            }
        })
    }

    deleteListFromStoreById = (id:number) => {
        this.lists && this.lists.data.forEach((list:List, key) => {
            if(list.id === id){
                this.remove(this.lists.data[key]);
            }
        })
    }

    remove = action((record:List) => {
        const filteredData = this.lists && this.lists.data.filter(item => item !== record);

        this.lists.data = filteredData;
    });
}

const ListsStore = new Store();

export default ListsStore;
