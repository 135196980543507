import React, { useState } from 'react';
import { observer } from 'mobx-react';
import UsersStore from 'stores/UsersStore';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import ToDoList from 'components/ToDoList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OnboardingModal from 'components/OnboardingModal';
import ScheduleStore from 'stores/ScheduleStore';

function ToDoListPage(props) {
  UsersStore.getUsers();
  UsersStore.getUser();
  ScheduleStore.getDefaultSchedule();

  const [sidebarOpen, setSidebarOpen] = useState(() => {
    return localStorage.getItem("sidebarOpen");
  });
  
  return (
      <>
        {UsersStore.user && UsersStore.user.data &&
          <OnboardingModal />
        }
        <div className={`-ml-64 w-full h-full float-right flex flex-col h-screen bg-slate-50 ${sidebarOpen ? 'md:pl-64' : ''}`}>
          <Header setSidebarOpen={() => {setSidebarOpen(!sidebarOpen);localStorage.setItem('sidebarOpen',!sidebarOpen)}} sidebarOpen={sidebarOpen} />
          <div className='container transition-all mx-auto flex-grow h-auto pt-10'>
            <ToDoList />
          </div>
          <Footer />
        </div>
        <div className={`h-full z-40 transition-all fixed ${!sidebarOpen ? '-ml-64' : ''}`} style={{zIndex:0}}><Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/></div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
  )
}

export default observer(ToDoListPage);