import React from 'react';
import { observer } from 'mobx-react';

const Footer = (): JSX.Element => {
    return (
        <footer className="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
            <div className='container mx-auto'>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://dooey.app" className="hover:underline">Dooey™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default observer(Footer)