import { observable, makeObservable, action } from 'mobx';
import Cookies from 'js-cookie';
import BaseStore from 'stores/BaseStore';
import ToastStore from 'stores/ToastStore';
import ToDoItem from 'interfaces/ToDoItem';
import ToDoItemSet from 'interfaces/ToDoItemSet';

let bearerToken = Cookies.get('dooey_access_token');
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class SizeMap {
    small:string = "15 Minutes";
    medium:string = "30 Minutes";
    large:string = "60 Minutes";
};

class PriorityMap {
    high:string = "High";
    low:string = "Low";
}

class Store extends BaseStore{
    toDoItems:ToDoItemSet = {is_fetching:false} as ToDoItemSet;
    isCreating = false;

    constructor() {
        super();
        makeObservable(this, {
            toDoItems: observable,
            isCreating: observable
        });
    }

    getToDoItems = (listId:number, force:boolean=false) => {
        if((this.toDoItems.is_fetching === false && !this.toDoItems.data) || force === true){
            if(force===false){
                this.toDoItems.is_fetching = true;
            }

            const options = this.preFetch({ method: 'get'});
            
            fetch(`${apiBaseUrl}/api/lists/${listId}/todo_items`, options)
            .then(response => response.json())
            .then((data) => {this.toDoItems = data;this.toDoItems.is_fetching=false});
        }
    }

    createNew = (listId:number) => {
        if(this.isCreating===false){
            this.isCreating = true;

            const options = this.preFetch({ 
                method: 'post',
                body: JSON.stringify({"list_id":listId})
            });
            
            fetch(`${apiBaseUrl}/api/todo_items`, options)
            .then(response => response.json())
            .then((data) => {
                if(data.success === false){
                    ToastStore.notifyError(`Failed to create a new task`);
                } else {
                    this.toDoItems.data.push(data.toDoItem);
                    this.isCreating=false;
                }
            });
        }
    }

    update = (params:ToDoItem, callback:Function|null = null) => {
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify(params)
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${params.id}`, options)
        .then(response => response.json())
        .then((data) => {callback && callback();});
    }

    schedule = (id:number, todoItem:ToDoItem) => {
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify({"is_scheduled": true})
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}/schedule`, options)
        .then(response => response.json())
        .then((data) => {todoItem.is_subscribed = true; todoItem.event_starttime = data.event_starttime});
    }

    unschedule = (id:number, todoItem:ToDoItem, callback:Function = ()=>{}) => {
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify({"is_scheduled": false})
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}/unschedule`, options)
        .then(response => response.json())
        .then((data) => {todoItem.is_subscribed = false; callback()});
    }

    reschedule = (id:number, event_starttime:string, event_endtime:string) => {
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify({"event_starttime": event_starttime,"event_endtime":event_endtime})
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}/reschedule`, options)
        .then(response => response.json())
        .then((data) => {});
    }

    setPriority = (id:number, todoItem:ToDoItem, isPriority:boolean) => {
        let priority = isPriority ? 'high' : 'low';
        let priorityMap = {} as PriorityMap;
        let summary = todoItem.summary;

        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify({"priority": priority})
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}/priority`, options)
        .then(response => response.json())
        .then((data) => {ToastStore.notifySuccess(`"${summary}" was marked as ${priorityMap[priority as keyof PriorityMap]} priority`)});
    }

    setSize = (id:number, size:string, todoItem:ToDoItem) => {
        todoItem.size = size;
        let summary = todoItem.summary;
        let sizeMap = {} as SizeMap;
        
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            }),
            body: JSON.stringify({"size": size})
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}/size`, options)
        .then(response => response.json())
        .then((data) => {ToastStore.notifySuccess(`"${summary}"'s duration was set to ${sizeMap[size as keyof SizeMap]}`)});
    }

    completeToDo = (id:number, orderKey:number) => {
        let summary = this.toDoItems.data[orderKey].summary;
        
        const options = this.preFetch({ 
            method: 'delete', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`
            }),
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${id}`, options)
        .then(response => response.json())
        .then((data) => {this.remove(this.toDoItems.data[orderKey]);ToastStore.notifySuccess(`"${summary}" was succesfully completed.`)});
    }

    autoGenerateSubtasks = (todoItem:ToDoItem, orderKey:number) => {
        
        const options = this.preFetch({ 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
            })
        });
        
        fetch(`${apiBaseUrl}/api/todo_items/${todoItem.id}/generate_subtasks`, options)
        .then(response => response.json())
        .then((data) => {
            ToastStore.notifySuccess(`Subtasks created for "${todoItem.summary}"`);
            data.toDoItems.forEach((toDoItem:ToDoItem) => {
                if(!this.toDoItems.data[orderKey].subtasks){
                    this.toDoItems.data[orderKey].subtasks = [];
                }
                this.toDoItems.data[orderKey].subtasks.push(toDoItem);
            });
        })
    }

    // handleEnterKeyPress = (eventKey:string, summary:string, id:number) => {
    //     if(eventKey === 'Enter'){
    //         ToDoStore.update({summary:summary, id:id});
    //     }
    // }

    remove = action((toDoItem:ToDoItem) => {
        const filteredData = this.toDoItems.data.filter(item => item !== toDoItem);

        this.toDoItems.data = filteredData;
    });
}

const ToDoStore = new Store();

export default ToDoStore;
