import { observable, makeObservable } from 'mobx';
import { toast } from 'react-toastify';

class Store {
    notificationsOn = true;

    constructor() {
        makeObservable(this, {
            notificationsOn: observable
        });
    }

    notifySuccess = (message:string) => {
        if(this.notificationsOn){
            toast.success(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    notifyInfo = (message:string) => {
        if(this.notificationsOn){
            toast.info(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    notifyError = (message:string) => {
        if(this.notificationsOn){
            toast.error(message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
}

const ToDoStore = new Store();

export default ToDoStore;
