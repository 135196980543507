import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ToDoStore from 'stores/ToDoStore';
import moment from "moment";
import { observer } from 'mobx-react';
import ToDoItem from 'interfaces/ToDoItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  todoItem:ToDoItem,
  popupState:{close:Function},
  isScheduled:boolean
}

const RescheduleModal = ({todoItem,isScheduled,popupState}:Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);popupState.close()}

  return (
    <div>
      <MenuItem onClick={() => {handleOpen()}} disabled={!isScheduled}>Reschedule</MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='block bg-white rounded-md border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reschedule Task
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='row mb-5 mt-5'>
                <TextField
                    id="datetime-local"
                    label="Start Date/Time"
                    type="datetime-local"
                    value={todoItem.event_starttime}
                    sx={{ width: 280 }}
                    onChange={(event)=>{
                        let newTime = moment(new Date(event.target.value));
                        let oldTime = moment(new Date(todoItem.event_starttime));
                        let duration = moment.duration(newTime.diff(oldTime));
                        let endTime = moment(new Date(todoItem.event_endtime));

                        endTime.add(duration.asMilliseconds(), 'milliseconds');
                        todoItem.event_endtime=endTime.format('YYYY-MM-DDTHH:mm:ss');

                        todoItem.event_starttime=moment(new Date(event.target.value)).format('YYYY-MM-DDTHH:mm:ss');
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
            <div className='row mb-5'>
                <TextField
                    id="datetime-local"
                    label="End Date/Time"
                    type="datetime-local"
                    value={todoItem.event_endtime}
                    sx={{ width: 280 }}
                    onChange={(event)=>{
                        todoItem.event_endtime=moment(new Date(event.target.value)).format('YYYY-MM-DDTHH:mm:ss');
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className='row content-center'>
                <Button
                    className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                    variant="contained"
                    type="button"
                    onClick={()=>{
                        ToDoStore.reschedule(todoItem.id,todoItem.event_starttime,todoItem.event_endtime);
                        handleClose();
                    }}>Reschedule</Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
export default observer(RescheduleModal)