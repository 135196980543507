import { observable, makeObservable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import UserSet from 'interfaces/UserSet';
import User from 'interfaces/User';
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class Store extends BaseStore {
    users:UserSet = {is_fetching:false} as UserSet;
    user:User = {is_fetching:false} as User;

    constructor() {
        super();
        makeObservable(this, {
            users: observable,
            user: observable
        });
    }

    getUsers = (force:boolean=false) => {
        if(this.users.is_fetching === false || force === true){
            if(force===false){
                this.users.is_fetching = true;
            }
                    
            const options = this.preFetch({ method: 'get'});
            
            // fetch(`${apiBaseUrl}/api/users`, options)
            // .then(response => response.json())
            // .then((data) => {this.users = data;this.users.is_fetching=false;});
        }
    }

    getUser = (force:boolean=false) => {
        if((this.user.is_fetching === false && !this.user.id) || force === true){
            if(force===false){
                this.user.is_fetching = true;
            }
                    
            const options = this.preFetch({ method: 'get'});
            
            fetch(`${apiBaseUrl}/api/user`, options)
            .then(response => response.json())
            .then((data) => {this.user = data;this.user.is_fetching=false;});
        }
    }

    toggleNotifications = (notificationsOn:boolean) => {     
        const options = this.preFetch({ 
            method: 'post',
            body: JSON.stringify({"notifications_on": notificationsOn})
        });
        
        fetch(`${apiBaseUrl}/api/user/notifications`, options)
        .then(response => response.json())
        .then((data) => {this.user = data;this.user.is_fetching=false;});
    }
}

const UsersStore = new Store();

export default UsersStore;
