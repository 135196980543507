import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import RescheduleModal from 'components/RescheduleModal';
import ToDoStore from 'stores/ToDoStore';
import { observer } from 'mobx-react';
import ToDoItem from 'interfaces/ToDoItem';

interface Props {
  todoItem:ToDoItem,
  setIsScheduling:Function,
  setEditModalOpen:Function
}

const TaskCardOptionsDropdown = ({todoItem,setIsScheduling,setEditModalOpen}:Props): JSX.Element => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="text" style={{minWidth:'23px'}} {...bindTrigger(popupState)}>
            <i className="fa-solid fa-ellipsis-vertical text-slate-800"></i>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={()=>{popupState.close();setEditModalOpen()}}>Edit</MenuItem>
            <RescheduleModal popupState={popupState} isScheduled={todoItem.is_subscribed} todoItem={todoItem}/>
            <MenuItem onClick={() => {setIsScheduling(true);todoItem.is_subscribed=false;popupState.close();ToDoStore.unschedule(todoItem.id, todoItem, ()=>{setIsScheduling(false)})}}>Unschedule</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
export default observer(TaskCardOptionsDropdown)