import ListsStore from 'stores/ListsStore';
import ToDoStore from 'stores/ToDoStore';
import { observer } from 'mobx-react';
import NewListModal from 'components/NewListModal';
import {useParams} from "react-router-dom";
import { Link } from "react-router-dom";
import UsersStore from 'stores/UsersStore';
import NotificationsSwitch from 'components/NotificationsSwitch';
import List from 'interfaces/List';
import ToDoItemSet from 'interfaces/ToDoItemSet';
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface Props {
    sidebarOpen:boolean,
    setSidebarOpen:Function
}

const Sidebar = ({sidebarOpen,setSidebarOpen}:Props): JSX.Element => {
    const { listId } = useParams()

    return(
        <aside className="w-64 flex flex-col h-full" aria-label="Sidebar">
            <div className='h-12 bg-indigo-600 dark:bg-indigo-600' >
                <div className={`float-right pt-2.5 pr-4 ${!sidebarOpen ? '-mr-12' : 'mr-0'}`}>
                    
                </div>
            </div>
            <div className="overflow-y-auto py-4 px-3 h-full bg-gray-800 dark:bg-gray-800 -mb-14" style={{marginTop:'-48px',paddingTop:'105px'}}>   
                <div className='w-full' style={{marginTop:'-40px'}}>
                    <div>
                        <NotificationsSwitch />
                    </div>
                </div>
                {
                    UsersStore.user && UsersStore.user.id &&
                    <div className="row flex justify-start items-center mt-3 mb-7">
                        <img className="w-10 h-10 rounded-full mr-5" src={UsersStore.user.profile_picture} alt="Default avatar" />
                        <span className='text-white text-base mr-9'>{UsersStore.user.name}</span>
                        <a href={`${apiBaseUrl}/settings/profile`}>
                            <i className="fa-solid fa-gear text-gray-500 hover:text-gray-600"></i>
                        </a>
                    </div>
                }
                {
                    !(UsersStore.user && UsersStore.user.id) &&
                    <div className="row flex justify-start items-center mt-3 mb-7">
                        <div role="status" className="animate-pulse">
                        <div className="flex justify-center items-center mt-4">
                            <svg className="mr-2 w-10 h-10 text-gray-200 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                            <div className="w-36 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mr-3"></div>
                        </div>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                }
                <ul className="space-y-2">
                    {ListsStore.lists && ListsStore.lists.data && ListsStore.lists.data.map((list:List) => {
                        if(list.id === parseInt(listId || '0')){
                            return( 
                                <li>
                                    <a href={`/lists/${list.id}`} className="flex items-center0">
                                        <button type="button" className="w-full text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base pr-5 pl-2 py-2 text-left">
                                            <i className={`fa-solid ${list.icon}`}></i>
                                            <span className="ml-3 text-md pl-1">{list.name}</span>
                                        </button>
                                    </a>
                                </li>
                            )
                        } else {
                            return(
                                <li>
                                    <Link
                                        to={`/lists/${list.id}`}
                                        onClick={(e)=>{
                                            if(window.innerWidth<768){
                                                setSidebarOpen(false)
                                            }
                                            ListsStore.getList(list.id,true);
                                            ToDoStore.getToDoItems(list.id,true);
                                            ToDoStore.toDoItems = {} as ToDoItemSet;
                                            ListsStore.list = {} as List;
                                        }}
                                        className="flex items-center p-2 text-base font-normal text-gray-100 rounded-lg dark:text-white hover:bg-white hover:text-neutral-800 dark:hover:text-black dark:hover:bg-gray-700"
                                    >
                                            <span className='w-5'><i className={`fa-solid ${list.icon} text-indigo-600`}></i></span>
                                            <span className="ml-3 text-md">{list.name}</span>
                                    </Link>
                                </li>
                            )
                        }
                    })}
                    <li>
                        <NewListModal />
                    </li>
                </ul>
            </div>
        </aside>
    )
}
export default observer(Sidebar)