import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ToDoStore from 'stores/ToDoStore';
import ToDoItem from 'interfaces/ToDoItem';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 30,
  padding: 9,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#581c87',
      },
      '& .MuiSwitch-thumb:before': {
        left: -1,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 20 20"><circle fill="${encodeURIComponent('#fff')}" cx="12" cy="19" r="2"></circle><path fill="${encodeURIComponent('#fff')}" d="M10 3h4v12h-4z"></path></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#7c3aed',
    width: 28,
    height: 28,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: -2,
      top: -1,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 20 20"><path fill="${encodeURIComponent('#fff')}" d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path><path fill="${encodeURIComponent('#fff')}" d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

interface Props {
  todoItem:ToDoItem
}

const PrioritySelect = ({todoItem}:Props): JSX.Element => {
  let switchTimeout:NodeJS.Timeout|undefined;

  return (
    <FormGroup>
      <FormControlLabel label='' style={{margin:'auto'}}
        control={<MaterialUISwitch sx={{ m: 0.3 }}
        onChange={
          (event)=>{
            if(switchTimeout){
              clearTimeout(switchTimeout);
            }
            switchTimeout = setTimeout(()=>{ToDoStore.setPriority(todoItem.id,todoItem,event.target.checked)},2000)
          }
        }
        defaultChecked={todoItem.priority === 'high' ? true : false} />}
      />
    </FormGroup>
  );
}
export default PrioritySelect;