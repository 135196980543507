import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ToDoStore from 'stores/ToDoStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import LoadingSpinner from 'components/LoadingSpinner';
import TaskCardOptionsDropdown from 'components/TaskCardOptionsDropdown';
import PrioritySelect from 'components/PrioritySelect';
import EditTaskModal from 'components/EditTaskModal';
import ScheduleButton from 'components/ScheduleButton';
import ToDoItem from 'interfaces/ToDoItem';
import Button from '@mui/material/Button';

interface Props {
    todoItem:ToDoItem,
    orderKey:number
}

const SubTaskCard = ({todoItem,orderKey}:Props): JSX.Element => {
    const [editable, setEditable] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isScheduling, setIsScheduling] = useState(false);

  return (
    <div className="block bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border hover:border-dashed hover:border-purple-400 ml-10">
        <div className="grid grid-cols-12">
            <div className="col-span-1 py-6 pl-2 mx-auto">                
                <div className="flex items-center">
                    {deleting === true &&
                    <LoadingSpinner />
                    }
                    {deleting === false &&
                    <FontAwesomeIcon className='text-xl text-gray-400' icon={faCircle} onClick={() => {ToDoStore.completeToDo(todoItem.id,orderKey);setDeleting(true)}}/>
                    }
                </div>
            </div>
            <div className="col-span-10 sm:col-span-9 md:col-span-9 lg:col-span-7 my-auto h-7 border-transparent pl-2 dark:text-white" onClick={() => {setEditable(true)}}> 
                {editable === true &&
                    <input name="todo_summary" className='m-auto w-full dark:bg-gray-800 focus:text-gray-300' defaultValue={todoItem.summary} onBlur={(event) => {setEditable(false); todoItem.summary=event.target.value;ToDoStore.update(todoItem); todoItem.summary = event.target.value}} onKeyPress={(event) => {if(event.key === 'Enter'){setEditable(false); todoItem.summary=(event.target as HTMLInputElement).value;ToDoStore.update(todoItem);}}}/>
                }
                {editable === false &&
                    todoItem.summary
                }
            </div>
            <div className="col-span-1 m-auto w-full hidden lg:inline"><PrioritySelect todoItem={todoItem}/></div>
            <div className="hidden sm:inline col-span-1 lg:col-span-2 m-auto">
                <ScheduleButton todoItem={todoItem} setIsScheduling={setIsScheduling} isScheduling={isScheduling}/>
            </div>
            <div className="col-span-1 m-auto"><TaskCardOptionsDropdown todoItem={todoItem} setIsScheduling={setIsScheduling} setEditModalOpen={()=>setEditModalOpen(!editModalOpen)}/></div>
        </div>
        <EditTaskModal setIsScheduling={setIsScheduling} isScheduling={isScheduling} setEditModalOpen={setEditModalOpen} editModalOpen={editModalOpen} todoItem={todoItem} />
    </div>
  )
}
export default observer(SubTaskCard)
