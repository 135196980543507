import React from 'react';
import { observer } from 'mobx-react';
import {Navigate} from "react-router-dom";
import ListsStore from 'stores/ListsStore';
import LoadingSpinner from 'components/LoadingSpinner';

function DashboardPage(props) {
  if(!ListsStore.lists) {
      return (
          
        <div className='w-full h-full grid place-content-center'>
            <LoadingSpinner size={20} className='m-auto'/>
        </div>
      )
  }

  return (
      <>
        {
          ListsStore.lists && ListsStore.lists.data && ListsStore.lists.data[0] &&
          <Navigate to={`/lists/${ListsStore.lists.data[0].id}`} />
        }
      </>
  )
}

export default observer(DashboardPage);
