import jwt_decode from "jwt-decode";
const Cookies = require('js-cookie');

let bearerToken = Cookies.get('dooey_access_token');
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface DecodedToken {
    exp:number
}

export default class BaseStore {
    fetch = (endpoint:string, options:{}): Promise<Response>  => {
        let decodedToken = {} as DecodedToken;
        try {
            decodedToken = jwt_decode(bearerToken);
        } catch (error) {
            window.location.href = `${apiBaseUrl}/dashboard`;
        }

        let mergedOptions = {...options, ...{
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
              })
        }};

        if((Date.now()/1000) > decodedToken.exp){
            window.location.href = `${apiBaseUrl}/dashboard`;
        }

        return fetch(`${apiBaseUrl}/api/${endpoint}`, mergedOptions)
        .then(response => response.json());
    }

    preFetch = (options:{}) => {
        let decodedToken = {} as DecodedToken;
        try {
            decodedToken = jwt_decode(bearerToken);
        } catch (error) {
            window.location.href = `${apiBaseUrl}/dashboard`;
        }

        let mergedOptions = {...options, ...{
            headers: new Headers({
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':'application/json'
              })
        }};

        if((Date.now()/1000) > decodedToken.exp){
            window.location.href = `${apiBaseUrl}/dashboard`;
        }
        return mergedOptions;
    }
}
