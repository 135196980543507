import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ToDoStore from 'stores/ToDoStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import LoadingSpinner from 'components/LoadingSpinner';
import TaskCardOptionsDropdown from 'components/TaskCardOptionsDropdown';
import PrioritySelect from 'components/PrioritySelect';
import EditTaskModal from 'components/EditTaskModal';
import ScheduleButton from 'components/ScheduleButton';
import ToDoItem from 'interfaces/ToDoItem';
import Button from '@mui/material/Button';
import SubTaskCard from './SubTaskCard';
import { IconButton } from '@mui/material';

interface Props {
    todoItem:ToDoItem,
    orderKey:number
}

const TaskCard = ({todoItem,orderKey}:Props): JSX.Element => {
    const [editable, setEditable] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isScheduling, setIsScheduling] = useState(false);

  return (
    <>
    <div className="block bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border hover:border-dashed hover:border-purple-400">
        <div className="grid grid-cols-12">
            <div className="col-span-1 py-6 pl-2 mx-auto">                
                <div className="flex items-center">
                    {deleting === true &&
                    <LoadingSpinner />
                    }
                    {deleting === false &&
                    <FontAwesomeIcon className='text-xl text-gray-400' icon={faCircle} onClick={() => {ToDoStore.completeToDo(todoItem.id,orderKey);setDeleting(true)}}/>
                    }
                </div>
            </div>
            <div className="col-span-10 sm:col-span-9 md:col-span-9 lg:col-span-6 my-auto h-7 border-transparent pl-2 dark:text-white" onClick={() => {setEditable(true)}}> 
                {editable === true &&
                    <input name="todo_summary" className='m-auto w-full dark:bg-gray-800 focus:text-gray-300' defaultValue={todoItem.summary} onBlur={(event) => {setEditable(false); todoItem.summary=event.target.value;ToDoStore.update(todoItem); todoItem.summary = event.target.value}} onKeyPress={(event) => {if(event.key === 'Enter'){setEditable(false); todoItem.summary=(event.target as HTMLInputElement).value;ToDoStore.update(todoItem);}}}/>
                }
                {editable === false &&
                    todoItem.summary
                }
            </div>
            <div className="col-span-1 m-auto w-full hidden lg:inline" onClick={()=>ToDoStore.autoGenerateSubtasks(todoItem, orderKey)}><button type='button' className='float-right py-2.5 px-1.5 lg:px-5 ml-0.5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-purple-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-transparent focus:text-purple-700 dark:focus:text-gray-400 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center'><i className="fa-solid fa-brain text-rose-400 fa-md"></i></button></div>
            <div className="col-span-1 m-auto w-full hidden lg:inline"><PrioritySelect todoItem={todoItem}/></div>
            <div className="hidden sm:inline col-span-1 lg:col-span-2 m-auto">
                <ScheduleButton todoItem={todoItem} setIsScheduling={setIsScheduling} isScheduling={isScheduling}/>
            </div>
            <div className="col-span-1 m-auto"><TaskCardOptionsDropdown todoItem={todoItem} setIsScheduling={setIsScheduling} setEditModalOpen={()=>setEditModalOpen(!editModalOpen)}/></div>
        </div>
        <EditTaskModal setIsScheduling={setIsScheduling} isScheduling={isScheduling} setEditModalOpen={setEditModalOpen} editModalOpen={editModalOpen} todoItem={todoItem} />
    </div>
    
    {todoItem.subtasks && todoItem.subtasks.map((toDo, key) => {
        return (
            <SubTaskCard todoItem={toDo} key={`todoitem-${toDo.id}`} orderKey={key}/>
        )
    })}
    </>
  )
}
export default observer(TaskCard)
