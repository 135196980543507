import * as React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ScheduleStore from 'stores/ScheduleStore';
import { observer } from 'mobx-react';
import Schedule from 'interfaces/Schedule';

const OnboardingScheduleTimeStyle = styled('div')(() => ({
    '.MuiFormControl-root input': {
        padding:'5px'
    }
}));
interface Props {
    itemKey: string,
    day: string
}

const OnboardingScheduleRow = ({ itemKey, day}: Props): JSX.Element => {
  return (
    <div className='grid grid-cols-12 mb-5'>
        <div className='col-span-2'>
            <h3>{day}</h3>
            </div>
            <div className='col-span-3'>
            <label htmlFor={`scheduled-day-switch-${itemKey}-start`} className="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" onChange={(event)=>{ScheduleStore.schedule[day.toLowerCase()+"_on" as keyof Schedule] = event.target.checked}} id={`scheduled-day-switch-${itemKey}-start`} checked={ScheduleStore.schedule[day.toLowerCase()+"_on"] ? true : false} className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">On</span>
            </label>
            </div>
            <div className='col-span-3'>
                <OnboardingScheduleTimeStyle>
                    <TextField
                        id={`datetime-local-${itemKey}-start`}
                        label="Start Time"
                        type="time"
                        className='p-1'
                        value={ScheduleStore.schedule[day.toLowerCase()+"_start" as keyof Schedule]}
                        onChange={(event)=>ScheduleStore.schedule[day.toLowerCase()+"_start" as keyof Schedule]=event.target.value}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </OnboardingScheduleTimeStyle>
            </div>
            <div className='col-span-1'>
            <h3 className='text-center'>to</h3>
            </div>
            <div className='col-span-3'>
                <OnboardingScheduleTimeStyle>
                    <TextField
                        id={`datetime-local-${itemKey}-end`}
                        label="End Time"
                        type="time"
                        value={ScheduleStore.schedule[day.toLowerCase()+"_end" as keyof Schedule]}
                        onChange={(event)=>ScheduleStore.schedule[day.toLowerCase()+"_end" as keyof Schedule]=event.target.value}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
            </OnboardingScheduleTimeStyle>
        </div>
    </div>
  );
}
export default observer(OnboardingScheduleRow);