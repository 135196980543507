import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

interface Props {
  setActiveStep:Function,
  activeStep:number
}

const OnboardingStepsFooter = ({setActiveStep,activeStep}:Props): JSX.Element => {
  const handleNext = () => {
    setActiveStep((prevActiveStep:number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep:number) => prevActiveStep - 1);
  };

  return (
    <MobileStepper
      variant="dots"
      steps={3}
      position="static"
      activeStep={activeStep}
      className="absolute bottom-0 w-full"
      sx={{ flexGrow: 1 }}
      nextButton={
        <button onClick={handleNext} disabled={activeStep === 2} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Next
            </span>
        </button>
      }
      backButton={
        <button onClick={handleBack} disabled={activeStep === 0} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Back
            </span>
        </button>
      }
    />
  );
}
export default OnboardingStepsFooter;