import React, { useState, useRef } from 'react';
import {toJS} from 'mobx';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import ScheduleButton from 'components/ScheduleButton';
import PrioritySelect from 'components/PrioritySelect';
import TaskSizeSelector from 'components/TaskSizeSelector';
import LoadingSpinner from 'components/LoadingSpinner';
import ToDoStore from 'stores/ToDoStore';
import ToDoItem from 'interfaces/ToDoItem';

const EditTaskModalStyle = styled('div')(() => ({
    '& .MuiBackdrop-root':{
        backgroundColor:'transparent !important'
    }
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth < 1024 ? '90%' : '900px',
  bgcolor: 'background.paper',
  p: 0,
};

interface Props {
    editModalOpen: boolean,
    setEditModalOpen: Function,
    todoItem: ToDoItem,
    setIsScheduling: Function,
    isScheduling: boolean
}

const EditTaskModal = ({ editModalOpen, setEditModalOpen, todoItem, setIsScheduling, isScheduling }: Props): JSX.Element => {
    const [isSaving, setIsSaving] = useState(false);
    const [tempSummary, setTempSummary] = useState(toJS(todoItem.summary));
    const editorRef = useRef<HTMLTextAreaElement>();

    const saveTask = ():void => {
        if (editorRef.current) {
            // todoItem.description = editorRef.current.getContent();
        }
        todoItem.summary = tempSummary;
        setIsSaving(true);
        ToDoStore.update(todoItem,()=>{setEditModalOpen(false);setIsSaving(false)});
    }

  return (
    <div>
      <EditTaskModalStyle>
        <Modal
            open={editModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className='block bg-white rounded-md border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border'>
                {isSaving &&
                    <div className='flex justify-center items-center p-10'>
                        <LoadingSpinner size={20} />
                    </div>
                }
                {!isSaving &&
                <div className='p-5 '>
                    <div className='flex'>
                        <div className='w-6/12 text-lg font-semibold'>
                            
                        </div>
                        <div className='w-6/12'>
                            <div className='row flex justify-end mb-5'>
                                <div onClick={()=>{setEditModalOpen(false)}} className="cursor-pointer">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row grid grid-cols-12 mb-10'>
                        <div className='col-span-4 grid grid-cols-12 text-center'>
                            <span className='col-span-12 text-indigo-600 font-semibold'>Duration</span>
                            <div className='col-span-12 flex justify-center mt-2'>
                                <TaskSizeSelector todoItem={todoItem}/>
                            </div>
                        </div>
                        <div className='col-span-4 text-center'>
                            <span className='col-span-12 text-indigo-600 font-semibold'>Priority</span>
                            <div className='col-span-12 flex justify-center mt-2'>
                                <PrioritySelect todoItem={todoItem}/>
                            </div>
                        </div>
                        <div className='col-span-4 text-center'>
                            <span className='col-span-12 text-indigo-600 font-semibold'>Schedule</span>
                            <div className='col-span-12 flex justify-center mt-2'>
                                <ScheduleButton todoItem={todoItem} setIsScheduling={setIsScheduling} isScheduling={isScheduling}/>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <input type="text" className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " value={tempSummary} onChange={(event)=>{setTempSummary(event.target.value)}}/>
                    </div>
                    <div className='row mb-5'>
                        {/* <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={todoItem.description}
                            apiKey='9j387442s9pnrcxkl31ozrajbibxwekzpednspzab61xna3k'
                            init={{
                            height: 250,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat | h1 h2 h3 | bullist numlist | alignleft aligncenter alignright | indent outdent',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        /> */}
                    </div>
                    <div className='row flex justify-end'>
                        <button onClick={saveTask} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                Save
                            </span>
                        </button>
                    </div>
                </div>
                }
            </Box>
        </Modal>
      </EditTaskModalStyle>
    </div>
  );
}
export default observer(EditTaskModal)