import React from 'react';
import { observer } from 'mobx-react';
import ToDoStore from 'stores/ToDoStore';
import ToDoItem from 'interfaces/ToDoItem';

interface Props {
  todoItem:ToDoItem,
}

const TaskSizeSelector = ({todoItem}:Props): JSX.Element => {
  return (
    <div className='flex justify-between items-start'>
        <div className={`rounded-3xl mr-2 font-bold text-xs ${todoItem.size === 'small' ? 'bg-lime-500 text-white' : 'bg-transparent text-slate-700'} cursor-pointer border border-2 border-lime-500 w-10 h-10 flex justify-center items-center`} onClick={()=>{ToDoStore.setSize(todoItem.id,'small',todoItem)}}>15m</div>
        <div className={`rounded-3xl mr-2 font-bold text-xs ${todoItem.size === 'medium' ? 'bg-yellow-300 text-white' : 'bg-transparent text-slate-700'} cursor-pointer border border-2 border-yellow-300 w-10 h-10 flex justify-center items-center`} onClick={()=>{ToDoStore.setSize(todoItem.id,'medium',todoItem)}}>30m</div>
        <div className={`rounded-3xl font-bold text-xs  ${todoItem.size === 'large' ? ' bg-red-500 text-white' : 'bg-transparent text-slate-700'} cursor-pointer border border-2 border-red-500 w-10 h-10 flex justify-center items-center`} onClick={()=>{ToDoStore.setSize(todoItem.id,'large',todoItem)}}>60m</div>
    </div>
  );
}
export default observer(TaskSizeSelector)