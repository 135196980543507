import React from 'react';
import { observer } from 'mobx-react';
import {
  Outlet
} from "react-router-dom";


function AppContainer(props) {
  
  return (
      <>
        <Outlet />
      </>
  )
}

export default observer(AppContainer);
