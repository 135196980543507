import * as React from 'react';
import Switch from '@mui/material/Switch';
import { observer } from 'mobx-react';
import ToastStore from 'stores/ToastStore';
import UsersStore from 'stores/UsersStore';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

function NotificationSwitch() {
  let switchTimeout:NodeJS.Timeout|null;

  const toggleNotifications = (notificationsOn:boolean) => {
    if(switchTimeout){
      clearTimeout(switchTimeout);
    }
    ToastStore.notificationsOn=notificationsOn;

    switchTimeout = setTimeout(()=>{UsersStore.toggleNotifications(notificationsOn)},2000)
  }
  
  return (
    <div className='w-full justify-end flex items-center'>
    {
      UsersStore.user && UsersStore.user &&
      <>
        <i className="fa-solid fa-bell-slash text-xs text-white"></i>
          <Switch {...label} defaultChecked={UsersStore.user.notifications_on} onChange={(event)=>toggleNotifications(event.target.checked)} size="small" color="primary" />
        <i className="fa-solid fa-bell text-sm text-white ml-0.5"></i>
      </>
    }
    {
      !(UsersStore.user) && 
        <div role="status" className="animate-pulse">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mr-3"></div>
          <span className="sr-only">Loading...</span>
        </div>
    }
    </div>
  );
}
export default observer(NotificationSwitch)
