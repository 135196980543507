import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react';
// import OnboardingSteps from 'components/OnboardingSteps';
import OnboardingStepsFooter from 'components/OnboardingStepsFooter';
import OnboardingScheduleRow from 'components/OnboardingScheduleRow';
import ScheduleStore from 'stores/ScheduleStore';
import UsersStore from 'stores/UsersStore';
const PlaceholderImage = require('images/laptop-1.png') as string;
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const scheduledDays = [
  {key:1,day:"Monday"},
  {key:2,day:"Tuesday"},
  {key:3,day:"Wednesday"},
  {key:4,day:"Thursday"},
  {key:5,day:"Friday"},
  {key:6,day:"Saturday"},
  {key:7,day:"Sunday"},
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  p: 0,
};

const OnboardingModal = (): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <div>
      <Modal
        open={!UsersStore.user.is_onboarded}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='block bg-white rounded-md border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border'>
          <div>
            <div className='row'>
                <div className='w-full'>
                    {/* <OnboardingSteps setActiveStep={setActiveStep} activeStep={activeStep}/> */}
                </div>
                <div className='pt-36 px-10' style={{height:'650px'}}>
                    <div className={activeStep === 0 ? 'inline' : 'hidden'}>
                      <h1 className='w-full text-center text-3xl font-semibold mb-10 mt-5'>Welcome to Dooey</h1>
                      <img src={PlaceholderImage} className="m-auto" width={300} alt="Welcome to Dooey"/>
                    </div>
                    <div className={`${activeStep === 1 ? 'inline' : 'hidden'} w-full`}>
                      {scheduledDays.map(scheduledDay => {
                        return (<OnboardingScheduleRow itemKey={`onboarding-day-${scheduledDay.key}`} day={scheduledDay.day}/>)
                      })}
                      <div className='w-full text-center flex justify-center'>
                        <button type="button" onClick={()=>{ScheduleStore.update(ScheduleStore.schedule)}} className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Save</button>
                      </div>                      
                    </div>
                    <div className={`${activeStep === 2 ? 'inline' : 'hidden'} flex justify-center`}>
                      <div className='mt-36'>
                        <a href={`${apiBaseUrl}/googlecalendar/auth`}>
                          <button className="bg-gray-100 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded inline-flex items-center" type="button">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1200px-Gmail_icon_%282020%29.svg.png" alt="GMail Logo" className="float-left" width='30'/>
                            <span className="pl-2">Connect to Gmail</span>
                          </button>
                        </a>
                      </div>
                    </div>
                </div>
                <OnboardingStepsFooter setActiveStep={setActiveStep} activeStep={activeStep}/>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default observer(OnboardingModal)