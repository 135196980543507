import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ToDoStore from 'stores/ToDoStore';
import ListsStore from 'stores/ListsStore';
import TaskCard from 'components/TaskCard';
import {useParams} from "react-router-dom";
import LoadingSpinner from 'components/LoadingSpinner';
import DeleteModal from 'components/DeleteModal';
import EditListModal from 'components/EditListModal';
import TodoListMobileMenu from 'components/TodoListMobileMenu';

const ToDoList = (): JSX.Element => {
    const [showSettings, setShowSettings] = useState(false);
    
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    let {listId} = useParams()
    
    ToDoStore.getToDoItems(parseInt(listId || '0'), false);
    ListsStore.getList(parseInt(listId || '0'), false);

    if(!ToDoStore.toDoItems.data || !ListsStore.list.id) {
        return (
            
        <div className='w-full h-full grid place-content-center'>
            <LoadingSpinner size={20} />
        </div>
        )
    }

    return (
        <>
        <DeleteModal list={ListsStore.list} setOpen={setDeleteModalOpen} open={deleteModalOpen} callback={()=>setShowSettings(false)}/>
        <EditListModal list={ListsStore.list} setOpen={setEditModalOpen} open={editModalOpen} callback={()=>setShowSettings(false)}/>

        {ToDoStore.toDoItems.data && ListsStore.list.id &&
            <div className="p-4 bg-transparent rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div className="flex justify-between items-center mb-12">
                    <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                        <i className={`fa-solid ${ListsStore.list.icon} pr-2`}></i> {ListsStore.list.name}
                    </h5>
                    <div className='justify-end hidden md:inline'>
                        <div className="inline-flex rounded-lg shadow-sm bg-gradient-to-br from-purple-600 to-blue-500 p-0.5" role="group">
                            <button onClick={() => ToDoStore.createNew(parseInt(listId || '0'))} disabled={ToDoStore.isCreating ? true : false} type="button" className={`inline-flex content center items-center py-1.5 px-4 text-sm font-medium text-indigo-900 bg-white rounded-l-md ${showSettings ? 'border-r' : ''} border-gray-200 hover:bg-transparent hover:text-white dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}>
                                {ToDoStore.isCreating && <div className='mr-1.5 flex content-content'><LoadingSpinner size={3}/></div>}
                                {!ToDoStore.isCreating && <i className="fa-solid fa-plus mr-1.5"></i>}
                                New Task
                            </button>
                            <button type="button" onClick={()=>setDeleteModalOpen(true)} className={`${showSettings ? '' : 'hidden'} inline-flex items-center py-1.5 px-4 text-sm font-medium text-red-600 bg-white border-r border-gray-200 hover:bg-red-600 hover:text-white dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}>
                                <i className="fa-solid fa-trash mr-1.5"></i> Delete
                            </button>
                            <button type="button" onClick={()=>setEditModalOpen(true)} className={`${showSettings ? '' : 'hidden'} inline-flex items-center py-1.5 px-4 text-sm font-medium text-indigo-900 bg-white border-gray-200 hover:bg-transparent hover:text-white dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}>
                                <i className="fa-solid fa-pen mr-1.5"></i> Rename
                            </button>
                            <button type="button" onClick={() => setShowSettings(!showSettings)} className={`inline-flex items-center py-1.5 px-4 text-sm font-medium text-indigo-900 bg-white rounded-r-md border-l border-gray-200 hover:bg-transparent hover:text-white dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}>
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flow-root">
                    {ToDoStore.toDoItems && ToDoStore.toDoItems.data && ToDoStore.toDoItems.data.map((toDo, key) => {
                        return (
                        <TaskCard todoItem={toDo} key={`todoitem-${toDo.id}`} orderKey={key}/>
                        )
                    })}
                </div>
                <TodoListMobileMenu
                    setDeleteModalOpen={setDeleteModalOpen}
                    setEditModalOpen={setEditModalOpen}
                    createNewItem={()=>ToDoStore.createNew(parseInt(listId || '0'))}
                />
            </div>
        }
        </>
    )
}

export default observer(ToDoList)
