import React from 'react';
import { observer } from 'mobx-react';
const Logo = require('images/dooey-logo.svg') as string;

interface Props {
    sidebarOpen: boolean,
    setSidebarOpen: Function
}

const Header = ({ sidebarOpen, setSidebarOpen}: Props): JSX.Element => {
    return (
        <header className='pb-5' style={{zIndex:1}}>
            <nav className={`bg-indigo-600 border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800 h-18 fixed w-full ${sidebarOpen ? 'md:-ml-64': ''}`}>
                <div className="container flex items-center flex-wrap">
                    <i className="fa-solid fa-bars text-lg text-white cursor-pointer mr-10" onClick={() => setSidebarOpen(!sidebarOpen)}></i>
                    <span className="flex items-center ">
                        <span id="dooey-logo" className="flex self-center text-white text-xl whitespace-nowrap dark:text-white">
                            <img src={Logo} width={30} className="mr-0.5" alt="Dooey Logo"/>
                            <span className='pt-1'>ooey</span>
                        </span>
                    </span>
                </div>
            </nav>
        </header>
    )
}

export default observer(Header)