import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ToDoStore from 'stores/ToDoStore';
import { observer } from 'mobx-react';
import ListsStore from 'stores/ListsStore';
import { useNavigate } from "react-router-dom";
import List from 'interfaces/List';
import ToDoItemSet from 'interfaces/ToDoItemSet';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  callback: Function,
  open: boolean,
  setOpen: Function,
  list:List
}

const DeleteModal = ({ callback , open, setOpen }: Props): JSX.Element => {
  let navigate = useNavigate();

  const deleteList = () => {
      ListsStore.delete(ListsStore.list);
      setOpen(false);ListsStore.list={} as List;
      ListsStore.getList(ListsStore.lists.data[0].id);
      ToDoStore.toDoItems={}as ToDoItemSet;ToDoStore.getToDoItems(ListsStore.lists.data[0].id);
      navigate(`/lists/${ListsStore.lists.data[0].id}`,{ replace: true });
      callback()
  }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='block bg-white rounded-md border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-3 border'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete List
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='row mb-5 mt-5'>
                <h3>Are you sure you'd like to delete this list?</h3>
            </div>
            <div className='grid grid-cols-12'>
                <div className='col-span-4 p-2'>
                    <button type="button" onClick={deleteList} className="w-full text-white bg-red-600 hover:bg-red-700 font-semibold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                </div>
                <div className='col-span-8 p-2'>
                    <button onClick={()=>{setOpen(false)}} className="w-full relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-semibold text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                        <span className="relative w-full px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                            No, my mistake
                        </span>
                    </button>
                </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
export default observer(DeleteModal)