import Schedule from 'interfaces/Schedule';
import { observable, makeObservable } from 'mobx';
import BaseStore from 'stores/BaseStore';
import ToastStore from 'stores/ToastStore';
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class Store extends BaseStore {
    schedule:Schedule = {is_fetching:false} as Schedule;

    constructor() {
        super();
        makeObservable(this, {
            schedule: observable
        });
    }
    
    getDefaultSchedule = (force=false) => {
        if(this.schedule.is_fetching === false || force === true){
            if(force===false){
                this.schedule.is_fetching = true;
            }
        
            const options = this.preFetch({ method: 'get'});
            
            // fetch(`${apiBaseUrl}/api/schedules/default`, options)
            // .then(response => response.json())
            // .then((data) => {this.schedule = data;this.schedule.is_fetching=false;});
        }
    }

    update = (schedule:Schedule) => {
        const options = this.preFetch({ 
            method: 'post',
            body: JSON.stringify(schedule)
        });
        
        fetch(`${apiBaseUrl}/api/schedules/${schedule.id}`, options)
        .then(response => response.json())
        .then(()=>{ToastStore.notifySuccess(`Schedule was succesfully updated.`)});
    }
}

const ScheduleStore = new Store();

export default ScheduleStore;
